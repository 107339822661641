// Ant Design Overrides  
.ant-tabs-tab
    font-size: 16px !important
    +xs
        font-size: 14px !important
        padding: 5px 0 !important
.ant-tabs-nav-list
    +xs
        margin-top: -10px
.ant-tabs-nav
    +xs
        margin-bottom: 5px !important
.ant-radio-button-wrapper
    +xs
        font-size: 14px
.ant-table-cell
    +xs
        padding: 5px 10px 5px 5px !important
.ant-table-selection-column
    +xs
        vertical-align: middle
.ant-drawer-body
    +xs
        padding: 0 !important