@import @import ../../../../../../styles/helpers

.toolbar-dropdown
    display: flex
    justify-content: flex-end
    margin-top: 12px
    margin-right: -25px
    height: 40px
    background-color: transparent
    border: 0px
    font-size: 18px
    color: $white !important
    +xs
        height: 35px
        margin-top: 9px
        margin-right: -35px
        font-size: 14px  

.toolbar-dropdown-btn-icon
    border-radius: 8px
    margin-top: -2px
    width: 35px !important
    height: 35px !important
    +xs
        width: 25px !important
        height: 25px !important

.toolbar-dropdown-item
    font-size: 18px
    +xs
        font-size: 14px