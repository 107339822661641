@import ../../../styles/helpers

.wrapper
  justify-content: center
  min-height: 100vh
  align-items: center

.card
  border: none
  background: none
  padding-top: 24px
  padding-bottom: 24px

.cardContent
    padding-top: 20px,
    padding-bottom: 20px,
    background: rgba(255,255,255, 0.95)

.btnWrapper
  display: flex
  justify-content: center

  button
    display: flex
    flex-direction: row
    align-items: center
    width: 'auto'
    padding: 8px
    gap: 12px
    border-radius: 8px
    border: 1px rgba(0, 0, 0, 0) solid
    cursor: pointer

    .icon
      font-size: 20px
      margin-left: 10px
      +xs
        font-size: 16px
        margin-left: 5px
    .text
      font-size: 16px
      +xs
        font-size: 14px

.signInButton 
  color: white
  background: #188EFF
  margin-right: 10px

.signUpButton
  color: white
  background: #58afff

.coinSwapButton
  color: white
  background: #4CAF50

.checkColor
  color:#ff7f50