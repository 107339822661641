@import ../../../styles/helpers

.cs-spin-wrapper
    margin-top: 20px
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    span
        font-size: 20px
        margin-top: 15px
        color: $text-color1