@import 'styles/helpers.sass'
@import 'styles/ant-design-overrides.sass'

body
    margin: 0
    font-family: 'Roboto', sans-serif
    font-size: 16px

th, span
    font-family: 'Roboto', sans-serif
    font-size: 16px