@import ../../../styles/helpers

.wrapper
  justify-content: center

.card
  border: none
  background: none

.logo
  width: 200px
  height: 200px
  +xs
    width: 200px

.header
  font-size: 24px
  font-weight: bold
  display: block

.btnWrapper
  margin-top: 20px
  display: flex
  justify-content: center

  button
    color: white
    background: #188EFF
    margin-right: 10px
    display: flex
    flex-direction: row
    align-items: center
    width: 'auto'
    padding: 8px
    gap: 12px
    border-radius: 8px
    border: 1px rgba(0, 0, 0, 0) solid
    cursor: pointer

    .icon
      font-size: 20px
      margin-left: 10px
      +xs
        font-size: 16px
        margin-left: 5px
    .text
      font-size: 16px
      +xs
        font-size: 14px

.paragraph
  font-size: 18px
  +xs
    font-size: 16px