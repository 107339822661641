@import ../../../../styles/helpers

.logo
    display: flex
    justify-content: center
    margin-left: -25px
    margin-top: 0px
    width: 72px
    height: 72px
    +xs
        margin-left: -35px
        margin-top: -4px
        width: 50px
        height: 50px