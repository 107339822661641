@import ../../../../styles/helpers

.card
  border: none
  background: none

.paragraph
  font-size: 18px
  +xs
    font-size: 16px

.btnWrapper
  display: flex
  justify-content: center
  margin-top: 10px

  a
    display: flex
    flex-direction: row
    align-items: center
    width: 'auto'
    padding: 8px
    gap: 12px
    border-radius: 8px
    border: 1px rgba(0, 0, 0, 0) solid
    cursor: pointer
    .icon
      font-size: 20px
      margin-left: 10px
      +xs
        font-size: 16px
        margin-left: 5px
    .text
      font-size: 18px
      +xs
        font-size: 14px

.btnDashboard
  color: white
  background: #188EFF
  margin-right: 10px
  &:hover
    color: white

.headerSuccess
  font-size: 22px
  color: #4CAF50
  +xs
    font-size: 19px

.headerConflict
  font-size: 22px
  color: #ff7f50
  +xs
    font-size: 19px

.headerError
  font-size: 22px
  color: #DC3645
  +xs
    font-size: 19px