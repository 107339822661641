// colors
$primary1: #ffffff
$background: #F7F9F9
$text-color1: #2B2D2D
$text-color1-dark: #ECECF1
$border-color: #E4EBEB

$primary1-dark: #343641
$background-dark: #444654
$border-color-dark: #2B2B32

$white: #ffffff
$black: #000000
$orange1: #FF7F50
$orange2: #FFA07A
$red1: #C0322F
$red2: #D85A54
$blue1: #188EFF
$blue1-dark: #4B9CD3
$blue2-dark: #1C4B73
$green1: #4CAF50

// media queries
=xxl
  @media only screen and (min-width: "1600px")
      @content
=xl
  @media only screen and (min-width: "1200px") and (max-width: "1599px")
      @content
=lg
  @media only screen and (min-width: "992px") and (max-width: "1199px")
      @content 
=md
  @media only screen and (min-width: "768px") and (max-width: "991px")
      @content
=sm
  @media only screen and (min-width: "576px") and (max-width: "767px")
      @content
=xs
  @media only screen and (max-width: "575px")
      @content