@import ../../../styles/helpers

.notificationCard
  margin-bottom: 12px
  border-radius: 8px
  background-color: #ffffff
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05)
  transition: all 0.3s ease
  
  &:hover
    transform: translateY(-2px)
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)

.notificationContent
  display: flex
  align-items: center
  padding: 0 4px
  position: relative

.avatar
  margin-right: 12px
  width: 40px
  height: 40px

.notificationDetails
  flex: 1
  min-width: 0
  margin-right: 40px
  display: flex
  flex-direction: column

.notificationHeader
  display: flex
  justify-content: space-between
  align-items: center
  margin-left: -20px
  margin-top: 3px


.username
  font-weight: 600
  margin-right: 8px

.timestamp
  color: rgba(0, 0, 0, 0.45)
  font-size: 12px
  white-space: nowrap
  margin-right: 5px

.fullTimestamp
  color: rgba(0, 0, 0, 0.35)
  font-size: 12px
  white-space: nowrap
  margin-top: 8px
  margin-right: 5px
  text-align: right

.description
  color: rgba(0, 0, 0, 0.85)
  word-break: break-word
  margin-left: -20px
  margin-right: 25px

.postPreview
  margin-top: 8px
  padding: 8px
  background-color: #f5f5f5
  border-radius: 4px
  font-size: 13px
  color: #595959

.unread
  background-color: #e6f7ff
  border-left: 3px solid #1890ff

.loadingContainer
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px

.loadingText
  margin-top: 16px
  color: #8c8c8c

.loadMoreButton
  width: auto
  min-width: 200px
  padding: 6px 16px
  background: rgba($blue2-dark, 0.9)
  color: white
  border: none
  border-radius: 4px
  cursor: pointer
  font-weight: 500
  transition: background 0.2s ease
  
  &:disabled
    background: rgba($blue2-dark, 0.5)
    cursor: not-allowed
  
  &:hover:not(:disabled)
    background: rgba($blue2-dark, 1)

.drawerHeader
  background-color: #fff
  height: 60px
  display: flex
  align-items: center
  padding: 0 24px
  
  :global(.ant-drawer-header-title)
    flex: 1
    justify-content: center
    
    :global(.ant-drawer-close)
      order: 2
      margin-right: -8px
      
    :global(.ant-drawer-title)
      font-size: 18px
      text-align: center

  +xs
    height: 45px

.card
  padding: 3px !important
  border-radius: 10px
  margin-bottom: 2px
  @media (max-width: 768px)
    margin-left: 5px
    margin-right: 5px
  &:last-child
    margin-bottom: 0

.drawer
  :global(.ant-drawer-body)
    padding: 0
    height: calc(100% - 60px)
    overflow: hidden
    
  :global(.ant-drawer-header)
    position: sticky
    top: 0
    z-index: 1
    border-bottom: 1px solid #f0f0f0

.drawerContent
  height: 100%
  overflow-y: auto
  padding: 16px
  
  scroll-behavior: smooth
  
  &::-webkit-scrollbar
    width: 6px
  
  &::-webkit-scrollbar-track
    background: #f1f1f1
  
  &::-webkit-scrollbar-thumb
    background: #888
    border-radius: 3px

.refreshButton
  margin-right: -8px
  color: $blue2-dark
  border: 1px solid $blue2-dark

.loadMoreContainer
  display: flex
  justify-content: center
  padding: 16px 0

  button
    min-width: 120px

.newNotificationsBar
  position: sticky
  z-index: 1
  background: rgba(255, 255, 255, 0.95)
  padding: 12px 8px
  border-bottom: 1px solid #f0f0f0
  margin-bottom: 8px
  backdrop-filter: blur(4px)
  display: flex
  align-items: center
  justify-content: center
  margin-top: -18px
  
  button
    width: auto
    min-width: 200px
    padding: 6px 16px
    background: rgba($blue2-dark, 0.9)
    transition: background 0.2s ease
    
    &:hover
      background: rgba($blue2-dark, 1)

.drawerTitle
  width: 100%
  justify-content: space-between

.bellIcon
  margin-right: 8px
  font-size: 14px

.typeIcon
  font-size: 18px
  color: $blue2-dark
  opacity: 0.8

.iconContainer
  position: absolute
  right: 10px
  height: 100%
  display: flex
  align-items: center

